import React, { useState, useEffect } from 'react'
import {
  getAssetsByIdentifier,
  getBookByTitleAndLibraryRef,
  createLibraryRef,
  getLendingById,
  transformSecondsToDateString,
  getAssetsByLibraryNotOwnedByUser,
  getAllLendedAssetsFromUser,
} from '../../utils'
import globalStyles from '../../globalStyles'
import localStyles from './DetailPageStyles'
import { Button, Content, Footer, Header, Spinner } from '../../components'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useGeolocation, useSentenceEncoder } from '../../contexts'
import { auth } from '../../firebase'
import { getAsset, isBook } from '../../asset'

const DetailPage = ({ match, location, history }) => {
  const globalClasses = globalStyles()
  const localClasses = localStyles()

  const { libraries } = useGeolocation()
  const { similarity } = useSentenceEncoder()

  const [assets, setAssets] = useState(null)
  const [assetMetaData, setAssetMetaData] = useState(null)
  const [similarBooks, setSimilarBooks] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (libraries) {
      if (getAsset().value === 'book') {
        initializeBooks()
      }

      getAssets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraries])

  const initializeBooks = async () => {
    const ref = createLibraryRef(libraries[0].id)
    const allBooks = await getAssetsByLibraryNotOwnedByUser(
      ref,
      auth.currentUser.uid
    )

    if (!allBooks) {
      return
    }

    let ownLendings = await getAllLendedAssetsFromUser(auth.currentUser.uid)
    if (!ownLendings) {
      ownLendings = []
    }

    const bookList = await getBookByTitleAndLibraryRef(
      match.params.identifier,
      ref
    )
    if (!bookList) {
      return
    }
    const book = bookList[0]

    // filter lended books (currently lending + history) + book to perform similarity on, from possible books
    const myTitles = ownLendings.map((book) => book.bookTitle)
    const possibleBooks = allBooks.filter((book) => {
      return match.params.identifier !== book.title && !myTitles.includes(book)
    })

    // calculate similarities
    const simResults = possibleBooks.map((tempBook) => {
      return {
        ...tempBook,
        score: similarity(book.embedding, tempBook.embedding),
      }
    })

    // sort by score, descending
    simResults.sort((a, b) => b.score - a.score)

    // get first 3 books if possible
    const simBooks = []
    if (simResults.length < 3) {
      simResults.forEach((simResult) => {
        simBooks.push(getBookById(simResult.id, possibleBooks))
      })
    } else {
      let i = 0
      while (i < 3) {
        simBooks.push(getBookById(simResults[i].id, possibleBooks))
        i++
      }
    }

    setSimilarBooks(simBooks)
  }

  const getBookById = (id, bookList) => {
    return bookList.find((tempBook) => tempBook.id === id)
  }

  const getAssets = async () => {
    const { identifier } = match.params

    const result = await getAssetsByIdentifier(identifier)

    if (result) {
      setAssetMetaData(result[0])

      const assetsWithReturnDate = await addReturnDatesToAssets(result)
      const assetsSortedByLibrary = {}

      // to make sure that the city passed from catalog is the first city
      if (location.state != null && location.state.libraryId) {
        const firstCity = getCityByLibraryId(location.state.libraryId)
        assetsSortedByLibrary[firstCity] = []
      }

      // sort assets per library
      assetsWithReturnDate.forEach((asset) => {
        const city = getCityByLibraryId(asset.libraryRef.id)

        if (city in assetsSortedByLibrary) {
          assetsSortedByLibrary[city].push(asset)
        } else {
          assetsSortedByLibrary[city] = [asset]
        }
      })
      setAssets(assetsSortedByLibrary)
    }
    setLoading(false)
  }

  const addReturnDatesToAssets = async (assets) => {
    const promises = assets.map(async (asset) => {
      if (asset.lendingRef) {
        const result = await getLendingById(asset.lendingRef.id)
        if (result) {
          return {
            ...asset,
            returnDate: result[0].returnDate,
            userName: result[0].userName,
          }
        }
        return asset
      }
      return asset
    })

    return await Promise.all(promises).then((resolvedAssets) => {
      return resolvedAssets
    })
  }

  const getCityByLibraryId = (libraryId) => {
    if (libraries) {
      const lib = libraries.find((library) => library.id === libraryId)
      return lib.city
    }
    return ''
  }

  const navigateToLendPage = () => {
    history.push('/lend')
  }

  const showBookDetails = () => {
    let imgSrc = assetMetaData.imgLink
    if (!imgSrc) {
      imgSrc = '/placeholder_book.png'
    }

    return (
      <div className={localClasses.bookDetailsRoot}>
        <div className={localClasses.imageContainer}>
          <img src={imgSrc} alt="" className={localClasses.imgWidth} />
        </div>
        <div className={localClasses.detailsContainer}>
          <h2 className={localClasses.author}>
            {assetMetaData.authors ? assetMetaData.authors.join(', ') : ''}
          </h2>
          <div className={localClasses.bookMetaInfo}>{showMetaInfo()}</div>
        </div>
      </div>
    )
  }

  const showMetaInfo = () => {
    return (
      <>
        {assetMetaData.publisher && (
          <span>Publisher: {assetMetaData.publisher}</span>
        )}
        {assetMetaData.publishDate && (
          <span>Publish date: {assetMetaData.publishDate}</span>
        )}
        {assetMetaData.language && (
          <span>Language: {assetMetaData.language}</span>
        )}
        {assetMetaData.pageCount && (
          <span>Pages: {assetMetaData.pageCount}</span>
        )}
      </>
    )
  }

  const showMetaInfoOnSmallScreen = () => {
    return (
      <div className={localClasses.metaInfoSmallScreenContainer}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Info
          </AccordionSummary>
          <AccordionDetails className={localClasses.column}>
            {showMetaInfo()}
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  const showDescription = () => {
    if (assetMetaData.description) {
      return (
        <div className={localClasses.marginTop}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Description
            </AccordionSummary>
            <AccordionDetails>{assetMetaData.description}</AccordionDetails>
          </Accordion>
        </div>
      )
    }
    return null
  }

  const showAvailability = () => {
    if (assets) {
      return (
        <div className={localClasses.marginTop}>
          {Object.entries(assets).map(([city, assetsOfCity]) => {
            return (
              <Accordion key={city} expanded={true}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Availability in {city}
                </AccordionSummary>
                <AccordionDetails className={localClasses.column}>
                  {showAssets(assetsOfCity)}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      )
    }
    return null
  }

  const showAssets = (assetsOfCity) => {
    const lendedAssets = assetsOfCity.filter((a) => a.returnDate)
    const available = assetsOfCity.length - lendedAssets.length
    return (
      <>
        {available > 0 && (
          <span className={localClasses.available}>
            Available ({available})
          </span>
        )}
        {lendedAssets.map((asset) => (
          <span key={asset.id} className={localClasses.notAvailabe}>
            {`Lended until ${transformSecondsToDateString(
              asset.returnDate.seconds
            )} by ${asset.userName}`}
          </span>
        ))}
      </>
    )
  }

  const showSimilarBooks = () => {
    if (similarBooks && similarBooks.length > 0) {
      return (
        <Accordion className={localClasses.marginTop} expanded={true}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            You may like...
          </AccordionSummary>
          <AccordionDetails className={localClasses.similarBooksContainer}>
            {similarBooks.map((simBook) => {
              let imgSrc = simBook.imgLink
              if (!imgSrc) {
                imgSrc = '/placeholder_book.png'
              }
              return (
                <div key={simBook.id} className={localClasses.similarBook}>
                  <img
                    src={imgSrc}
                    alt={`Book suggestion: ${simBook.title}`}
                    className={localClasses.similarBookImg}
                  />
                  <span className={localClasses.similarBookTitle}>
                    {simBook.title}
                  </span>
                </div>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )
    }
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header history={history} title="Detail" />
      <Content contentContainerStyle={localClasses.contentContainer}>
        {loading ? (
          <div className={localClasses.spinnerContainer}>
            <Spinner />
          </div>
        ) : assets ? (
          <>
            <div className={localClasses.column}>
              <h1 className={localClasses.title}>{match.params.identifier}</h1>
              {isBook() && (
                <>
                  {showBookDetails()}
                  {showMetaInfoOnSmallScreen()}
                </>
              )}
              <Button type="button" onClick={navigateToLendPage}>
                Lend {getAsset().value}
              </Button>
              {showDescription()}
              {showAvailability()}
              {isBook() && showSimilarBooks()}
            </div>
          </>
        ) : (
          <h1>Nothing found</h1>
        )}
      </Content>
      <Footer />
    </div>
  )
}

export default DetailPage
