import { getAsset } from '../asset'
import { firestore } from '../firebase'

const _getAssets = () => {
  return firestore
    .collection('assets')
    .where('assetType', '==', getAsset().value)
}

const _getLendings = () => {
  return firestore
    .collection('lendings')
    .where('assetType', '==', getAsset().value)
}

const getAssetByQrCode = async (qrCode) => {
  return await _getAssets()
    .where('qrCode', '==', qrCode)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getBooksByTitleAndLibraryRef = async (title, libraryRef) => {
  return await _getAssets()
    .where('title', '==', title)
    .where('libraryRef', '==', libraryRef)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

// very similar to 'getBooksByTitleAndLibraryRef', but the result is limited to 1
const getBookByTitleAndLibraryRef = async (title, libraryRef) => {
  return await _getAssets()
    .where('title', '==', title)
    .where('libraryRef', '==', libraryRef)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getAssetsByIdentifier = async (identifier) => {
  return await _getAssets()
    .where(getAsset().identifier, '==', identifier)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getAssetsByLibraryNotOwnedByUser = async (libraryRef, userId) => {
  return await _getAssets()
    .where('libraryRef', '==', libraryRef)
    .where('ownerId', '!=', userId)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getAllLendedAssetsFromUser = async (userId) => {
  return await _getLendings()
    .where('userId', '==', userId)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const _getLendingByUserIdAndIsAssetReturned = async (
  userId,
  isAssetReturned
) => {
  return await _getLendings()
    .where('userId', '==', userId)
    .where('isAssetReturned', '==', isAssetReturned)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getCurrentlyLendingByUserId = async (userId) => {
  return await _getLendingByUserIdAndIsAssetReturned(userId, false)
}

const getLendingHistoryByUserId = async (userId) => {
  return await _getLendingByUserIdAndIsAssetReturned(userId, true)
}

const getAllAssetsByOwnerId = async (ownerId) => {
  return await _getAssets()
    .where('ownerId', '==', ownerId)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getAdminUid = async () => {
  const result = await firestore
    .collection('admins')
    .limit(1)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
  return result[0].id
}

const getAllLibraries = async () => {
  return await firestore
    .collection('libraries')
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getBookSuggestionsByLibraryRef = async (amount, libraryRef) => {
  return await _getAssets()
    .where('libraryRef', '==', libraryRef)
    .limit(amount)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const getLendingById = async (id) => {
  return await _getLendings()
    .where('__name__', '==', id)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const createLibraryRef = (libraryId) => {
  return firestore.collection('libraries').doc(libraryId)
}

const createLendingRef = (lendingId) => {
  if (lendingId) {
    return firestore.collection('lendings').doc(lendingId)
  }
  return firestore.collection('lendings').doc()
}

const createAssetRef = (assetId) => {
  return firestore.collection('assets').doc(assetId)
}

const getAllAssetsByLibraryRef = async (libraryRef) => {
  return await _getAssets()
    .where('libraryRef', '==', libraryRef)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        return null
      }
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
    .catch((error) => {
      console.log('Error getting documents: ', error)
    })
}

const addAsset = async (asset, history) => {
  await firestore
    .collection('assets')
    .add(asset)
    .then((docRef) => {
      history.goBack()
    })
    .catch((error) => {
      console.error('Error adding document: ', error)
    })
}

const addLending = async (lending, history) => {
  let batch = firestore.batch()
  // add lending (+ generate id)
  let lendingRef = createLendingRef()
  batch.set(lendingRef, lending)
  // set lender of asset
  batch.update(lending.assetRef, {
    lender: lending.userId,
    lendingRef: lendingRef,
  })
  batch
    .commit()
    .then(() => {
      history.goBack()
    })
    .catch((error) => {
      console.log(error)
    })
}

const updateReturnDateByLendingId = async (lendingId, returnDate) => {
  await firestore
    .collection('lendings')
    .doc(lendingId)
    .update({ returnDate: new Date(returnDate) })
    .then()
    .catch((error) => {
      console.log(error)
    })
}

const returnAsset = async (lendingRef, assetId) => {
  let batch = firestore.batch()
  // update status of lending
  batch.update(lendingRef, { isAssetReturned: true, returnedAt: new Date() })
  // update status of asset
  let assetRef = createAssetRef(assetId)
  batch.update(assetRef, { lender: null, lendingRef: null })
  await batch
    .commit()
    .then()
    .catch((error) => {
      console.log(error)
    })
}

const deleteAsset = async (lendingRef, assetId) => {
  let batch = firestore.batch()
  // update status of lending
  if (lendingRef) {
    batch.update(lendingRef, { isAssetReturned: true, returnedAt: new Date() })
  }
  // delete asset
  let assetRef = createAssetRef(assetId)
  batch.delete(assetRef)
  await batch
    .commit()
    .then()
    .catch((error) => {
      console.log(error)
    })
}

export {
  getAssetByQrCode,
  getBooksByTitleAndLibraryRef,
  getBookByTitleAndLibraryRef,
  getAssetsByIdentifier,
  getAssetsByLibraryNotOwnedByUser,
  getAllLendedAssetsFromUser,
  getCurrentlyLendingByUserId,
  getLendingHistoryByUserId,
  getAllAssetsByOwnerId,
  getAdminUid,
  getAllLibraries,
  getBookSuggestionsByLibraryRef,
  getLendingById,
  createLibraryRef,
  createLendingRef,
  createAssetRef,
  getAllAssetsByLibraryRef,
  addAsset,
  addLending,
  updateReturnDateByLendingId,
  returnAsset,
  deleteAsset,
}
