import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import globalStyles from '../../globalStyles'
import localStyles from './HomePageStyles'
import { Button, Card, Content, Footer, Header } from '../../components'
import { capitalize, getCurrentlyLendingByUserId } from '../../utils'
import { getAsset, isBook } from '../../asset'
import { createAssetFromLendingObject } from '../../utils/AssetUtils'

const HomePage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = localStyles()

  const [currentlyLending, setCurrentlyLending] = useState(null)
  const [lendingIdToRemove, setLendingIdToRemove] = useState(null)

  useEffect(() => {
    const getCurrentlyLending = async () => {
      const result = await getCurrentlyLendingByUserId(auth.currentUser.uid)
      setCurrentlyLending(result)
    }

    getCurrentlyLending()
  }, [])

  // update currentlyLending after an asset is returned
  useEffect(() => {
    if (lendingIdToRemove) {
      const currentlyLendingCopy = [...currentlyLending]
      const idx = currentlyLendingCopy.findIndex(
        (lending) => lending.id === lendingIdToRemove
      )
      currentlyLendingCopy.splice(idx, 1)
      setCurrentlyLending(currentlyLendingCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lendingIdToRemove])

  const showCurrentlyLending = () => {
    if (currentlyLending !== null && currentlyLending.length !== 0) {
      return (
        <div>
          {currentlyLending.map((lending) => {
            return (
              <Card
                key={lending.id}
                type="currentlyLending"
                asset={createAssetFromLendingObject(lending)}
                setLendingIdToRemove={setLendingIdToRemove}
              />
            )
          })}
        </div>
      )
    }
    return <span>You're not lending any {getAsset().plural}</span>
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title={`${capitalize(getAsset().value)} Library`} />
      <Content contentContainerStyle={localClasses.contentContainer}>
        <div>
          <h2 className={localClasses.subtitleText}>Currently lending</h2>
          {showCurrentlyLending()}
        </div>
        <Button type="button" onClick={() => history.push('/catalog')}>
          Browse catalog
        </Button>
        <Button type="button" onClick={() => history.push('/lend')}>
          Checkout {getAsset().value}
        </Button>
        {isBook() && (
          <Button type="button" onClick={() => history.push('/suggestion')}>
            Surprise me...
          </Button>
        )}

        <div className={localClasses.buttonContainer}>
          <Button type="button" onClick={() => history.push('/history')}>
            My lending history
          </Button>
          <Button
            type="button"
            onClick={() => history.push(`/my${getAsset().plural}`)}
          >
            {capitalize(getAsset().plural)} I own
          </Button>
        </div>
      </Content>
      <Footer />
    </div>
  )
}

export default HomePage
