import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { matchSorter } from 'match-sorter'

const SearchBook = ({ books, searchedBook, setSearchedBook }) => {
  const filterOptions = (objList, { inputValue }) =>
    matchSorter(objList, inputValue, { keys: ['title'] })

  return (
    <Autocomplete
      options={books ?? []}
      getOptionLabel={(option) => option.title}
      value={searchedBook}
      onChange={(event, newValue) => {
        setSearchedBook(newValue)
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search by title" margin="none" />
      )}
      style={{ marginBottom: '10px' }}
      filterOptions={filterOptions}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue)
        const parts = parse(option.title, matches)

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}

export default SearchBook
