import React, { useState, useEffect } from 'react'
import globalStyles from '../../globalStyles'
import { makeStyles } from '@material-ui/core'
import { Card, Content, Footer, Header } from '../../components'
import { useGeolocation, useSentenceEncoder } from '../../contexts'
import { auth } from '../../firebase'
import {
  createLibraryRef,
  getAllLendedAssetsFromUser,
  getAssetsByLibraryNotOwnedByUser,
} from '../../utils'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: '800px',
    '@media(max-width: 820px)': {
      width: '90%',
    },
  },
  subtitleText: {
    marginTop: '10px',
  },
})

const BookSuggestionPage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = useStyles()

  const { libraries } = useGeolocation()
  const { similarity } = useSentenceEncoder()

  const [similarBooks, setSimilarBooks] = useState(null)
  const [randomBooks, setRandomBooks] = useState(null)

  useEffect(() => {
    if (libraries) {
      initializeBooks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraries])

  const initializeBooks = async () => {
    const ref = createLibraryRef(libraries[0].id)
    const allBooks = await getAssetsByLibraryNotOwnedByUser(
      ref,
      auth.currentUser.uid
    )

    if (!allBooks) {
      return
    }

    let ownLendings = await getAllLendedAssetsFromUser(auth.currentUser.uid)

    if (!ownLendings) {
      const randomNumber = Math.floor(Math.random() * allBooks.length)
      setRandomBooks([allBooks[randomNumber]])
      return
    }
    // remove ownLendings
    const myTitles = ownLendings.map((book) => book.bookTitle)
    const possibleBooks = allBooks.filter((book) => {
      return !myTitles.includes(book.title)
    })

    // sort ownLendings based on returnDate, from newest to oldest
    // currentlyLending will be placed after the oldest returned book
    // ==> currently lended books will only be used if the amount of returned books < 2
    ownLendings.sort((a, b) => b.returnedAt - a.returnedAt)

    const lastLendedTitles = ownLendings.slice(0, 2)
    let lastLendedBooks = []
    lastLendedTitles.forEach((lending) => {
      const book = allBooks.find((book) => book.title === lending.bookTitle)
      if (book) {
        lastLendedBooks.push(book)
      }
    })

    // calculate similarities
    let weight = 1
    let simResults = []
    lastLendedBooks.forEach((book) => {
      simResults.push(
        ...possibleBooks.map((tempBook) => {
          return {
            ...tempBook,
            score: similarity(book.embedding, tempBook.embedding) * weight,
          }
        })
      )
      weight = weight * 0.75
    })

    // sort elements based on score (high to low)
    simResults.sort((a, b) => b.score - a.score)

    // get the (first) unique elements
    let uniqueBooks = simResults.filter(
      (element, idx, array) =>
        array.findIndex((t) => t.id === element.id) === idx
    )

    setSimilarBooks(uniqueBooks.slice(0, 2))
    if (uniqueBooks.length > 2) {
      const randomNumber =
        2 + Math.floor(Math.random() * uniqueBooks.slice(2).length)
      setRandomBooks([uniqueBooks[randomNumber]])
    }
  }

  const showCards = (cardList) => {
    if (cardList !== null && cardList.length > 0) {
      return cardList.map((book) => <Card key={book.id} asset={book} />)
    } else {
      return <span>Nothing found.</span>
    }
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title="Suggestions" history={history} />
      <Content contentContainerStyle={localClasses.contentContainer}>
        <h2 className={localClasses.subtitleText}>You may like...</h2>
        {showCards(similarBooks)}
        <h2 className={localClasses.subtitleText}>Feeling lucky...</h2>
        {showCards(randomBooks)}
      </Content>
      <Footer />
    </div>
  )
}

export default BookSuggestionPage
