import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: '800px',
    '@media(max-width: 820px)': {
      width: '90%',
    },
  },
  searchButton: {
    marginTop: '0px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  bookDetailsRoot: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    margin: '20px 0',
    fontSize: 'xx-large',
    '@media(max-width: 500px)': {
      fontSize: 'x-large',
    },
  },
  author: {
    fontSize: 'x-large',
    '@media(max-width: 500px)': {
      fontSize: 'large',
    },
  },
  imageContainer: {
    display: 'flex',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    flexDirection: 'column',
    width: '20%',
    justifyContent: 'center',
    '@media(max-width: 820px)': {
      width: '30%',
    },
    '@media(max-width: 500px)': {
      width: '50%',
    },
  },
  imgWidth: {
    width: '100%',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: '20px',
    marginTop: '20px',
    '@media(max-width: 820px)': {
      marginTop: '10px',
    },
    '@media(max-width: 500px)': {
      marginTop: '0px',
      justifyContent: 'center',
    },
  },
  bookMetaInfo: {
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 500px)': {
      display: 'none',
    },
  },
  metaInfoSmallScreenContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    '@media(min-width: 500px)': {
      display: 'none',
    },
  },
  marginTop: {
    marginTop: '20px',
  },
  available: {
    color: '#1c8147',
    fontWeight: 'bold',
  },
  notAvailabe: {
    color: '#bf4a4a',
    fontWeight: 'bold',
  },
  similarBooksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  similarBook: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  similarBookImg: {
    '@media(min-width: 800px)': {
      height: '200px',
    },
    '@media(max-width: 800px)': {
      height: '160px',
    },
    '@media(max-width: 500px)': {
      height: '80px',
    },
  },
  similarBookTitle: {
    marginTop: '10px',
    textAlign: 'center',
    padding: '0px 10px',
    '@media(max-width: 800px)': {
      fontSize: 'small',
    },
    '@media(max-width: 500px)': {
      display: 'none',
    },
  },
  spinnerContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default useStyles
