import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: 800,
    '@media(max-width: 820px)': {
      width: '90%',
    },
  },
  spinnerContainer: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
})

export default useStyles
