import React, { createContext, useContext, useState } from 'react'
// eslint-disable-next-line no-unused-vars
import * as tf from '@tensorflow/tfjs'
import * as use from '@tensorflow-models/universal-sentence-encoder'

const SentenceEncorderContext = createContext()

const useSentenceEncoder = () => {
  return useContext(SentenceEncorderContext)
}

const SentenceEncoderProvider = ({ children }) => {
  const [model, setModel] = useState(null)

  const loadModel = () => {
    if (!model) {
      use.load().then((sentenceEncoder) => {
        tf.enableProdMode()
        setModel(sentenceEncoder)
      })
    }
  }

  const dot = (a, b) => {
    var hasOwnProperty = Object.prototype.hasOwnProperty
    var sum = 0
    for (var key in a) {
      if (hasOwnProperty.call(a, key) && hasOwnProperty.call(b, key)) {
        sum += a[key] * b[key]
      }
    }
    return sum
  }

  const similarity = (a, b) => {
    var magnitudeA = Math.sqrt(dot(a, a))
    var magnitudeB = Math.sqrt(dot(b, b))
    if (magnitudeA && magnitudeB) return dot(a, b) / (magnitudeA * magnitudeB)
    else return false
  }

  return (
    <SentenceEncorderContext.Provider value={{ model, similarity, loadModel }}>
      {children}
    </SentenceEncorderContext.Provider>
  )
}

export { SentenceEncoderProvider, useSentenceEncoder }
