import { makeStyles } from '@material-ui/core'
import { getBackgroundColor } from '../../utils'

const useStyles = makeStyles({
  header: {
    backgroundColor: getBackgroundColor(),
    minHeight: '82px',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 250,
    display: 'flex',
    flexDirection: 'row',
  },
  textColor: {
    color: 'white',
  },
  whitespaceContainer: {
    flex: 1,
    '@media(max-width: 900px)': {
      flex: 0,
    },
  },
  titleContainer: {
    flex: 4,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
  },
  contentWrapper: {
    // used to set image and title on same height (while being centerd)
    flex: 1,
    display: 'flex',
    alignItems: 'baseline',
  },
  logo: {
    width: '60px',
    '@media(max-width: 900px)': {
      width: '40px',
    },
    cursor: 'pointer',
  },
  title: {
    color: 'white',
    fontSize: 'xx-large',
    fontWeight: 'normal',
    marginLeft: '20px',
    '@media(max-width: 900px)': {
      fontSize: 'x-large',
    },
    '@media(max-width: 500px)': {
      fontSize: 'large',
    },
  },
  backButtonIcon: {
    alignSelf: 'center',
    color: 'white',
    underline: 'none',
    '&:hover': {
      color: 'rgb(255,145,58, 0.85)',
      cursor: 'pointer',
    },
  },
  backButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '20px',
    padding: '5px',
    width: '34px',
  },
  signOutButton: {
    color: 'white',
    backgroundColor: 'inherit',
    border: 'none',
    padding: '10px',
    alignSelf: 'center',
    margin: '0 20px',
    '&:hover': {
      color: 'rgb(255,145,58, 0.85)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
})

export default useStyles
