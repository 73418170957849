import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { deleteAsset } from '../../utils'

const removeHandler = async (
  lendingRef,
  assetId,
  setAssetIdToRemove,
  setRemoveOpen
) => {
  await deleteAsset(lendingRef, assetId)
  setAssetIdToRemove(assetId)
  setRemoveOpen(false)
}

const showButton = ({
  setRemoveOpen,
  removeOpen,
  title,
  lendingRef,
  assetId,
  setAssetIdToRemove,
  styles,
}) => {
  return (
    <>
      <Button
        type="button"
        onClick={() => setRemoveOpen(true)}
        variant="outlined"
        className={styles.marginTop}
      >
        Remove
      </Button>
      <Dialog
        open={removeOpen}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Are you sure that you want to remove this this book?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              removeHandler(
                lendingRef,
                assetId,
                setAssetIdToRemove,
                setRemoveOpen
              )
            }
          >
            Remove
          </Button>
          <Button onClick={() => setRemoveOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { showButton }
