import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import {
  Input,
  Button,
  Card,
  SearchBook,
  Header,
  Content,
  Footer,
} from '../../components'
import globalStyles from '../../globalStyles'
import localStyles from './CatalogPageStyles'
import { useGeolocation } from '../../contexts'
import {
  initializeCities,
  inputChangedHandler,
  createLibraryRef,
  getAllAssetsByLibraryRef,
  useLastAssetElementRef,
  capitalize,
} from '../../utils'
import { getAsset, isBook, isHardware } from '../../asset'

const CatalogPage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = localStyles()
  const { libraries } = useGeolocation()

  const [assets, setAssets] = useState(null)
  const [assetsForSearch, setAssetsForSearch] = useState(null)
  const [searchedAsset, setSearchedAsset] = useState(null)

  const { lastAssetElementRef, listToUpdate, setListToUpdate, amountToAdd } =
    useLastAssetElementRef(assets)

  // state for library
  const [libraryForm, setLibraryForm] = useState({
    libraryLocation: {
      elementType: 'select',
      // contains the html properties
      elementConfig: {
        options: [],
        label: 'Office location',
      },
      value: '',
    },
  })

  // to get the libraries and set the initial value for libraryForm (first value in the list)
  useEffect(() => {
    if (libraries) {
      const options = libraries.map((library) => {
        return {
          value: library.id,
          displayValue: library.city,
        }
      })
      initializeCities(options, 'libraryLocation', libraryForm, setLibraryForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraries])

  useEffect(() => {
    if (libraryForm.libraryLocation.value) {
      getAssetsFromLibrary()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryForm])

  const getAssetsFromLibrary = async () => {
    const ref = createLibraryRef(libraryForm.libraryLocation.value)
    const result = await getAllAssetsByLibraryRef(ref)

    if (result) {
      configureAssets(getFilteredAssets(result))
    } else {
      configureAssets(null)
    }
  }

  const getFilteredAssets = (allAssets) => {
    var filteredAssets = []
    allAssets.forEach((item) => {
      var i = filteredAssets.findIndex((x) => {
        if (isBook()) return x.title === item.title
        if (isHardware()) return x.name === item.name
        return false
      })
      if (i <= -1) {
        filteredAssets.push(item)
      } else {
        // if asset is lended
        if (filteredAssets[i].lender) {
          // check if current asset is not lended
          // replace old asset with new asset to show that asset is available
          if (!item.lender) {
            filteredAssets[i] = item
          }
        }
      }
    })

    return filteredAssets
  }

  const configureAssets = (filteredAssets) => {
    setAssets(filteredAssets)
    if (filteredAssets) {
      setListToUpdate(filteredAssets.slice(0, amountToAdd))
    } else {
      setListToUpdate(null)
    }
    setSearchedAsset(null)
    setAssetsForSearch(filteredAssets)
  }

  const searchHandler = async (event) => {
    event.preventDefault()
    if (searchedAsset) {
      const idx = assetsForSearch.findIndex(
        (asset) => asset.id === searchedAsset.id
      )
      setAssets([assetsForSearch[idx]])
      setListToUpdate([assetsForSearch[idx]].slice(0, amountToAdd))
    } else {
      setAssets(assetsForSearch)
      if (assetsForSearch) {
        setListToUpdate(assetsForSearch.slice(0, amountToAdd))
      } else {
        setListToUpdate(null)
      }
    }
  }

  const showForm = () => {
    const formElement = libraryForm['libraryLocation']
    let form = (
      <form onSubmit={searchHandler}>
        <Input
          key="libraryLocation"
          type={formElement.elementType}
          config={formElement.elementConfig}
          value={formElement.value}
          changed={(event) =>
            inputChangedHandler(
              event,
              'libraryLocation',
              libraryForm,
              setLibraryForm
            )
          }
        />
        {isBook() && (
          <div>
            <Button type="submit" parentStyle={localClasses.searchButton}>
              Search {getAsset().value}
            </Button>
          </div>
        )}
      </form>
    )
    return form
  }

  const showAssets = () => {
    if (listToUpdate) {
      return listToUpdate.map((asset, index) => {
        let ref = null
        if (index === listToUpdate.length - 1) {
          ref = lastAssetElementRef
        }

        let pathname
        if (isBook()) {
          pathname = asset.title
        } else if (isHardware()) {
          pathname = asset.name
        }

        return (
          <Link
            key={asset.id}
            to={{
              pathname: `/catalog/${pathname}`,
              state: { libraryId: libraryForm.libraryLocation.value },
            }}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            <Card type="catalog" ref={ref} asset={asset} />
          </Link>
        )
      })
    }
    return null
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title="Catalog" history={history} />
      <Content contentContainerStyle={localClasses.contentContainer}>
        {isBook() && (
          <>
            <h2>Search {getAsset().value}</h2>
            <SearchBook
              books={assetsForSearch}
              searchedBook={searchedAsset}
              setSearchedBook={setSearchedAsset}
            />
          </>
        )}
        {showForm()}
        {assets && (
          <span className={localClasses.assetsAmount}>
            {capitalize(getAsset().plural)} found: {assets.length}
          </span>
        )}
        {showAssets()}
      </Content>
      <Footer />
    </div>
  )
}

export default CatalogPage
