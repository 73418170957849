import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  card: {
    backgroundColor: 'white',
    marginTop: '15px',
    padding: '10px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'small',
    boxShadow: '0px 0px 20px rgb(220,220,220)',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    justifyContent: 'center',
  },
  imgWidth: {
    width: '100%',
  },
  bookInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: '20px',
    marginTop: '20px',
    '@media(max-width: 820px)': {
      marginTop: '10px',
    },
    '@media(max-width: 500px)': {
      marginLeft: '10px',
      marginTop: '0px',
    },
  },
  title: {
    fontWeight: 'bolder',
    fontSize: 'x-large',
    marginBottom: '20px',
    '@media(max-width: 820px)': {
      fontSize: 'large',
      marginBottom: '10px',
    },
    '@media(max-width: 500px)': {
      fontSize: 'medium',
      marginBottom: '0px',
    },
  },
  subtitle: {
    fontSize: 'large',
    '@media(max-width: 820px)': {
      fontSize: 'medium',
    },
    '@media(max-width: 500px)': {
      fontSize: 'small',
    },
  },
  marginTop: {
    marginTop: '10px',
  },
  flexOne: {
    flex: 1,
  },
  // ------------ currently lending ------------
  warning: {
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: '#f02323',
    marginBottom: '10px',
  },
  // ------------ catalog ------------
  extraInfo: {
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 500px)': {
      display: 'none',
    },
  },
  available: {
    color: '#1c8147',
    fontWeight: 'bold',
  },
  notAvailabe: {
    color: '#bf4a4a',
    fontWeight: 'bold',
  },
})

export default useStyles
