import isEmail from 'validator/lib/isEmail'

/**
 * To check if the value is valid
 * @param {*} value the value of the input
 * @param {*} rules the validation rules that the input must meet
 * @returns boolean if the value was valid
 */
export const checkValidity = (value, rules) => {
  let isValid = true
  const inputValue = value.trim()
  // if there are no rules
  if (!rules) {
    return isValid
  }
  if (rules.required) {
    isValid = inputValue !== '' && isValid
  }

  if (rules.email) {
    isValid = isEmail(inputValue) && isValid
  }

  return isValid
}

/**
 * Updates the form and checks for validity
 * @param {*} event the default event that is triggerd when an input changes
 * @param {*} id the id of the element in the form
 * @param {*} form the form from which an input has changed
 * @param {*} updateFormMethod the method to update the form values
 * @param {*} updateValidForm optional, to update the status (valid or not valid) of the overal form
 */
export const inputChangedHandler = (
  event,
  id,
  form,
  updateFormMethod,
  updateValidForm = null
) => {
  // create deep copy of bookForm
  const { updatedForm, updatedFormElement } = getDeepCopyOfFormElement(form, id)

  // update value
  updatedFormElement.value = event.target.value
  updatedFormElement.touched = true

  // check validity
  updatedFormElement.valid = checkValidity(
    updatedFormElement.value,
    updatedFormElement.validation
  )
  updatedForm[id] = updatedFormElement

  updateFormMethod(updatedForm)

  // check if overall state is valid
  if (updateValidForm !== null) {
    let validForm = true
    for (let key in updatedForm) {
      validForm = updatedForm[key].valid && validForm
    }
    updateValidForm(validForm)
  }
}

/**
 * Maps the form object to an array
 * @param {*} form the form object to convert to an array
 * @returns an array containing every form object
 */
export const getFormElementsArray = (form) => {
  const formElementsArray = []
  for (let key in form) {
    formElementsArray.push({
      id: key,
      ...form[key],
    })
  }
  return formElementsArray
}

export const initializeCities = (
  options,
  formField,
  form,
  updateFormMethod
) => {
  const { updatedForm, updatedFormElement } = getDeepCopyOfFormElement(
    form,
    formField
  )

  updatedFormElement.elementConfig.options = options
  updatedFormElement.value = options[0].value
  updatedForm[formField] = updatedFormElement
  updateFormMethod(updatedForm)
}

export const getDeepCopyOfFormElement = (form, id) => {
  const updatedForm = { ...form }
  const updatedFormElement = { ...updatedForm[id] }
  return { updatedForm: updatedForm, updatedFormElement: updatedFormElement }
}
