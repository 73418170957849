import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 820px)': {
      width: '90%',
    },
  },
  searchButton: {
    marginTop: '0px',
  },
  assetsAmount: {
    marginTop: '10px',
    alignSelf: 'flex-end',
  },
})

export default useStyles
