import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: 800,
    '@media(max-width: 820px)': {
      width: '90%',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 5,
  },
  logoutButton: {
    marginLeft: 10,
  },
  welcomeMessage: {
    fontSize: 'small',
  },
  subtitleText: {
    marginTop: 10,
  },
  buttonContainer: {
    marginTop: 75,
  },
})

export default useStyles
