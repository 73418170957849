import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  spinner: {
    color: 'black',
  },
})

const Spinner = () => {
  const localClasses = useStyles()

  return <CircularProgress className={localClasses.spinner} />
}

export default Spinner
