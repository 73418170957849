import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { DatePicker } from '../../components'
import {
  updateReturnDateByLendingId,
  createLendingRef,
  returnAsset,
  getStringFromDate,
} from '../../utils'
import { getAsset } from '../../asset'

const renewHandler = async (
  lendingId,
  returnDateInPopup,
  setRenewOpen,
  setReturnDate,
  setOverdue
) => {
  await updateReturnDateByLendingId(lendingId, returnDateInPopup)
  setRenewOpen(false)
  setReturnDate(new Date(returnDateInPopup))
  setOverdue(false)
}

const returnHandler = async (
  lendingId,
  assetId,
  setLendingIdToRemove,
  setReturnOpen
) => {
  const ref = createLendingRef(lendingId)
  await returnAsset(ref, assetId)
  setLendingIdToRemove(lendingId)
  setReturnOpen(false)
}

const datePickerHandler = (
  date,
  setReturnDateInPopup,
  dayAfterMaxDate,
  minDate,
  setRenewDisabled
) => {
  setReturnDateInPopup(date.setHours(23, 59, 59, 999))
  if (
    // eslint-disable-next-line eqeqeq
    date == 'Invalid Date' ||
    date > dayAfterMaxDate ||
    date < minDate
  ) {
    setRenewDisabled(true)
  } else {
    setRenewDisabled(false)
  }
}

const showButtons = ({
  setRenewOpen,
  renewOpen,
  title,
  returnDateInPopup,
  setReturnOpen,
  returnOpen,
  lendingId,
  setReturnDate,
  setOverdue,
  setReturnDateInPopup,
  assetId,
  setLendingIdToRemove,
  renewDisabled,
  setRenewDisabled,
  styles,
}) => {
  const minDate = new Date().setDate(new Date().getDate() + 1)
  const maxDate = new Date().setDate(new Date().getDate() + 30)
  let dayAfterMaxDate = new Date(maxDate)
  dayAfterMaxDate.setDate(dayAfterMaxDate.getDate() + 1)

  return (
    <ButtonGroup className={styles.marginTop}>
      <Button
        type="button"
        onClick={() => setRenewOpen(true)}
        className={styles.flexOne}
      >
        Renew
      </Button>
      <Dialog
        open={renewOpen}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DatePicker
            infoText="Renew until"
            selected={returnDateInPopup}
            onChange={(date) => {
              datePickerHandler(
                date,
                setReturnDateInPopup,
                dayAfterMaxDate,
                minDate,
                setRenewDisabled
              )
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={renewDisabled}
            onClick={() =>
              renewHandler(
                lendingId,
                returnDateInPopup,
                setRenewOpen,
                setReturnDate,
                setOverdue
              )
            }
          >
            Renew
          </Button>
          <Button onClick={() => setRenewOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button
        type="button"
        onClick={() => setReturnOpen(true)}
        className={styles.flexOne}
      >
        Return
      </Button>
      <Dialog
        open={returnOpen}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to return this {getAsset().value}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              returnHandler(
                lendingId,
                assetId,
                setLendingIdToRemove,
                setReturnOpen
              )
            }
          >
            Return {getAsset().value}
          </Button>
          <Button onClick={() => setReturnOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </ButtonGroup>
  )
}

const showWarning = ({ isAssetReturned, overdue, styles }) => {
  if (!isAssetReturned && overdue) {
    return (
      <span className={styles.warning}>
        Please return this {getAsset().value}!
      </span>
    )
  }
}

const showCurrentlyLendingInfo = ({ returnDate, assetOwnerName }) => (
  <>
    <span>Expires: {getStringFromDate(returnDate)}</span>
    {assetOwnerName && <span>Owner: {assetOwnerName}</span>}
  </>
)

export { showButtons, showWarning, showCurrentlyLendingInfo }
