import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
})

const DatePicker = ({ infoText, selected, onChange, minDate, maxDate }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          format="dd/MM/yyyy"
          label={infoText}
          value={selected}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DatePicker
