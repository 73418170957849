import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
  },
  sideContainer: {
    flex: 1,
  },
  contentContainer: {
    margin: '10px 0',
    width: '400px',
    '@media(max-width: 420px)': {
      width: '90%',
    },
  },
})

export default useStyles
