import React, { useState, useEffect } from 'react'
import { Input } from '../../components'
import { getFormElementsArray, inputChangedHandler } from '../../utils'

const HardwareFields = ({ setPartialAsset, setPartialAssetValid }) => {
  const [hardwareForm, setHardwareForm] = useState({
    name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        label: 'Name',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    description: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        label: 'Description',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
  })

  useEffect(() => {
    // To let the ancestor (AddPage) know the values of the fields
    const formData = {}
    for (let key in hardwareForm) {
      formData[key] = hardwareForm[key].value
    }
    setPartialAsset(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hardwareForm])

  return (
    <>
      {getFormElementsArray(hardwareForm).map((formElement) => {
        return (
          <Input
            key={formElement.id}
            type={formElement.elementType}
            config={formElement.elementConfig}
            value={formElement.value}
            changed={(event) =>
              inputChangedHandler(
                event,
                formElement.id,
                hardwareForm,
                setHardwareForm,
                setPartialAssetValid
              )
            }
            invalid={!formElement.valid}
            touched={formElement.touched}
            shouldValidate={formElement.validation}
          />
        )
      })}
    </>
  )
}

export default HardwareFields
