import React from 'react'
import localStyles from './HeaderStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { auth } from '../../firebase'
import { useLocation } from 'react-router-dom'

const Header = ({ title, history }) => {
  const localClasses = localStyles()
  const { pathname } = useLocation()

  const logoutHandler = async () => {
    try {
      await auth.signOut()
    } catch (error) {
      console.log('something went wrong when logging out')
    }
  }

  const goToHomePage = () => {
    if (pathname !== '/') {
      history.push('/')
    }
  }

  return (
    <div className={localClasses.header}>
      <div className={localClasses.backButtonContainer}>
        {history && (
          <ArrowBackIcon
            onClick={() => history.goBack()}
            className={localClasses.backButtonIcon}
            title="back button"
          />
        )}
      </div>
      <div className={localClasses.whitespaceContainer} />
      <div className={localClasses.titleContainer}>
        <div className={localClasses.contentWrapper}>
          <img
            src="/ml6.svg"
            alt="Logo ML6"
            className={localClasses.logo}
            onClick={goToHomePage}
          />
          <h1 className={localClasses.title}>{title}</h1>
        </div>
      </div>
      <div className={localClasses.whitespaceContainer} />
      <button
        type="button"
        className={localClasses.signOutButton}
        onClick={logoutHandler}
      >
        Change account
      </button>
    </div>
  )
}

export default Header
