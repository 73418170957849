import React from 'react'
import { isBook, isHardware } from '../../asset'
import BookFields from './BookFields'
import HardwareFields from './HardwareFields'

// This file is responsible for handling the implementations of the functions in a certain environment (book or hardware).
// AddPage doesn't need to know if it uses a 'book' or 'hardware',
// this responsibility is managed by this file.

const getValueToEmbed = (asset) => {
  if (isBook()) {
    let valueToEmbed = asset.title
    if (asset.description) {
      valueToEmbed += ` ${asset.description}`
    }
    return valueToEmbed
  }

  if (isHardware()) {
    return `${asset.name} ${asset.description}`
  }
}

const downloadQr = (asset, setQrCodeDownloaded) => {
  var link = document.createElement('a')
  if (isBook()) {
    link.download = `${asset.title}.png`
  } else if (isHardware()) {
    link.download = `${asset.name}.png`
  }
  link.href = document.getElementById('qrCodeId').toDataURL()
  link.click()
  link = null
  setQrCodeDownloaded(true)
}

const createUniqueQrCode = (owner, asset) => {
  if (isBook()) {
    return `${owner}@title@${asset.title}@timestamp@${Date.now()}`
  }
  if (isHardware()) {
    return `${owner}@title@${asset.name}@timestamp@${Date.now()}`
  }
}

const UniqueAssetFields = (props) => {
  return (
    <>
      {isBook() && <BookFields {...props} />}
      {isHardware() && <HardwareFields {...props} />}
    </>
  )
}

export default UniqueAssetFields

export { getValueToEmbed, downloadQr, createUniqueQrCode }
