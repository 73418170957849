import { getAsset } from '../asset'
import { capitalize } from './StringUtils'

const getScanText = ({
  qrCode,
  assetNotFound = false,
  assetAlreadyLended = false,
  assetAlreadyLendedByMe = false,
  loading = false,
}) => {
  if (qrCode) {
    return 'Scan succesful'
  } else if (assetNotFound) {
    return `${capitalize(getAsset().value)} not found`
  } else if (assetAlreadyLended) {
    return `${capitalize(getAsset().value)} is already lended`
  } else if (assetAlreadyLendedByMe) {
    return `You already lended this ${getAsset().value}`
  } else if (loading) {
    return `Loading`
  }
  return 'Scan qr code'
}

const isValid = ({
  qrCode,
  assetNotFound = false,
  assetAlreadyLended = false,
  assetAlreadyLendedByMe = false,
  loading = false,
}) => {
  // if 1 parameter is false => isValid is false
  let isValid = true
  isValid = (qrCode !== null || loading) && isValid
  isValid = !assetNotFound && isValid
  isValid = !assetAlreadyLended && isValid
  isValid = !assetAlreadyLendedByMe && isValid
  return isValid
}

export { getScanText, isValid }
