import React, { useState, forwardRef } from 'react'
import useStyles from './CardStyles'
import {
  showButtons as showCurrentlyLendingButtons,
  showWarning,
  showCurrentlyLendingInfo,
} from './currentlyLending'
import { showButton as showOwnBookButton } from './ownBook'
import { getStringFromDate } from '../../utils/TransformDate'
import { isBook } from '../../asset'

const Card = forwardRef((props, ref) => {
  const classes = useStyles()
  // variables used in more than 1 card
  const { type: cardType } = props
  const { title, name, description, assetId } = props.asset

  let { authors, imgLink: imgSrc } = props.asset
  authors = authors ? authors.join(', ') : ''
  if (!imgSrc) {
    imgSrc = '/placeholder_book.png'
  }

  // code for currently lending and lending history (only some props and returnDate are needed for lending history)
  const {
    startDateInSeconds,
    returnDateInSeconds,
    isAssetReturned,
    lendingId,
    assetOwnerName,
    returnedAtInSeconds,
  } = props.asset
  const { setLendingIdToRemove } = props

  const initializeDate = () => {
    let initialDate = new Date().setDate(new Date().getDate() + 14)
    return new Date(initialDate).setHours(23, 59, 59, 999)
  }

  const [returnDate, setReturnDate] = useState(
    new Date(returnDateInSeconds * 1000)
  )
  const [returnDateInPopup, setReturnDateInPopup] = useState(initializeDate())
  const [renewOpen, setRenewOpen] = useState(false)
  const [overdue, setOverdue] = useState(
    () => new Date(returnDateInSeconds * 1000) < new Date()
  )
  const [returnOpen, setReturnOpen] = useState(false)

  // to disable renew button (in popup) if selected date is not valid
  const [renewDisabled, setRenewDisabled] = useState(false)

  const getCurrentlyLendingObject = () => {
    return {
      isAssetReturned,
      setRenewOpen,
      renewOpen,
      title: title ? title : name,
      returnDateInPopup,
      setReturnOpen,
      returnOpen,
      lendingId,
      setReturnDate,
      overdue,
      setOverdue,
      setReturnDateInPopup,
      assetId,
      setLendingIdToRemove,
      returnDate,
      assetOwnerName,
      renewDisabled,
      setRenewDisabled,
      styles: classes,
    }
  }

  const showLendingHistoryInfo = () => (
    <>
      <span>
        {`From ${getStringFromDate(
          new Date(startDateInSeconds * 1000)
        )} To ${getStringFromDate(new Date(returnedAtInSeconds * 1000))}`}
      </span>
      {assetOwnerName && <span>Owner: {assetOwnerName}</span>}
    </>
  )

  // code for catalog
  const { lender, publishDate, pageCount } = props.asset
  const showCatalogInfo = () => {
    return (
      <>
        {!lender ? (
          <span className={classes.available}>Available</span>
        ) : (
          <span className={classes.notAvailabe}>Lend</span>
        )}
        {isBook() && (
          <div className={classes.extraInfo}>
            {pageCount && <span>Pages: {pageCount}</span>}
            {publishDate && <span>Publish Date: {publishDate}</span>}
          </div>
        )}
      </>
    )
  }

  // code for own asset
  const { setAssetIdToRemove } = props
  const { lendingRef } = props.asset
  const [removeOpen, setRemoveOpen] = useState(false)

  const getOwnAssetObject = () => {
    return {
      setRemoveOpen,
      removeOpen,
      title,
      lendingRef,
      assetId,
      setAssetIdToRemove,
      styles: classes,
    }
  }

  return (
    <div className={classes.card} ref={ref}>
      {cardType === 'currentlyLending' &&
        showWarning(getCurrentlyLendingObject())}
      <div className={classes.contentContainer}>
        {isBook() && (
          <div className={classes.imageContainer}>
            <img
              src={imgSrc}
              alt={`BookCover for: ${title}`}
              className={classes.imgWidth}
            />
          </div>
        )}
        <div className={classes.bookInfoContainer}>
          {isBook() ? (
            <>
              <h1 className={classes.title}>{title}</h1>
              <span className={classes.subtitle}>{authors}</span>
            </>
          ) : (
            <>
              <h1 className={classes.title}>{name}</h1>
              <span className={classes.subtitle}>{description}</span>
            </>
          )}
          {cardType === 'currentlyLending' &&
            showCurrentlyLendingInfo(getCurrentlyLendingObject())}
          {cardType === 'catalog' && showCatalogInfo()}
          {cardType === 'lendingHistory' && showLendingHistoryInfo()}
        </div>
      </div>
      {cardType === 'currentlyLending' &&
        showCurrentlyLendingButtons(getCurrentlyLendingObject())}
      {cardType === 'ownBook' && showOwnBookButton(getOwnAssetObject())}
    </div>
  )
})

export default Card
