import React from 'react'
import useStyles from './FooterStyles'
import { Link } from 'react-router-dom'

const Footer = () => {
  const localClasses = useStyles()

  return (
    <footer className={localClasses.root}>
      <span className={localClasses.footerText}>
        {`ML6 - `}
        <Link to="/contact" className={localClasses.link}>
          contact
        </Link>
      </span>
    </footer>
  )
}

export default Footer
