import React, { useState } from 'react'
import {
  DatePicker,
  Button,
  QrReader,
  Header,
  Content,
  Footer,
} from '../../components'
import { makeStyles } from '@material-ui/core'
import {
  getScanText,
  isValid,
  getAssetByQrCode,
  addLending,
  createAssetRef,
} from '../../utils'
import { auth } from '../../firebase'
import globalStyles from '../../globalStyles'
import { getAsset, isBook, isHardware } from '../../asset'

const useStyles = makeStyles({
  infoContainer: {
    marginBottom: '5px',
    padding: '0 10px',
  },
})

const LendPage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = useStyles()
  const currentUser = auth.currentUser

  const minDate = new Date().setDate(new Date().getDate() + 1)
  const maxDate = new Date().setDate(new Date().getDate() + 30)
  let dayAfterMaxDate = new Date(maxDate)
  dayAfterMaxDate.setDate(dayAfterMaxDate.getDate() + 1)

  const [qrCode, setQrCode] = useState(null)
  const [assetNotFound, setAssetNotFound] = useState(false)
  const [assetAlreadyLended, setAssetAlreadyLended] = useState(false)
  const [assetAlreadyLendedByMe, setAssetAlreadyLendedByMe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDateValid, setIsDateValid] = useState(true)

  const initializeDate = () => {
    let initialDate = new Date().setDate(new Date().getDate() + 14)
    return new Date(initialDate).setHours(23, 59, 59, 999)
  }
  const [returnDate, setReturnDate] = useState(initializeDate())

  const submitHandler = async () => {
    // get qr code
    let asset = await getAssetByQrCode(qrCode)

    setQrCode(null)
    initializeErrors()

    // asset found?
    if (!asset) {
      setAssetNotFound(true)
      return
    }

    asset = asset[0]
    // is asset being lended?
    if (asset.lender) {
      if (asset.lender === currentUser.uid) {
        setAssetAlreadyLendedByMe(true)
      } else {
        setAssetAlreadyLended(true)
      }
      return
    }

    setLoading(true)
    const lending = createLendingObject(asset)
    await addLending(lending, history)
  }

  const createLendingObject = (asset) => {
    const defaultFields = {
      assetRef: createAssetRef(asset.id),
      assetOwnerName: asset.ownerName,
      userId: currentUser.uid,
      userEmail: currentUser.email,
      userName: currentUser.displayName,
      startDate: new Date(),
      returnDate: new Date(returnDate),
      isAssetReturned: false,
      returnedAt: null,
      assetType: getAsset().value,
    }

    if (isBook()) {
      return {
        ...defaultFields,
        bookTitle: asset.title,
        authors: asset.authors,
        bookImgLink: asset.imgLink,
      }
    }
    if (isHardware()) {
      return {
        ...defaultFields,
        assetName: asset.name,
      }
    }
  }

  const datePickerHandler = (date) => {
    setReturnDate(date.setHours(23, 59, 59, 999))
    if (
      // eslint-disable-next-line eqeqeq
      date == 'Invalid Date' ||
      date > dayAfterMaxDate ||
      date < minDate
    ) {
      setIsDateValid(false)
    } else {
      setIsDateValid(true)
    }
  }

  const createValidationRules = () => {
    return {
      qrCode: qrCode,
      assetNotFound: assetNotFound,
      assetAlreadyLended: assetAlreadyLended,
      assetAlreadyLendedByMe: assetAlreadyLendedByMe,
      loading: loading,
    }
  }

  const initializeErrors = () => {
    setAssetNotFound(false)
    setAssetAlreadyLended(false)
    setAssetAlreadyLendedByMe(false)
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title={`Lend ${getAsset().value}`} history={history} />
      <Content>
        <div className={localClasses.infoContainer}>
          <span>
            {`Please scan the QR code, typically found on the back of the ${
              getAsset().value
            }.`}
          </span>
        </div>
        <QrReader
          qrCode={qrCode}
          updateStateMethod={setQrCode}
          scanText={getScanText(createValidationRules())}
          valid={isValid(createValidationRules())}
          initializeErrors={initializeErrors}
        />
        <DatePicker
          infoText="Lending until"
          selected={returnDate}
          onChange={(date) => {
            datePickerHandler(date)
          }}
          minDate={new Date().setDate(new Date().getDate() + 1)}
          maxDate={new Date().setDate(new Date().getDate() + 30)}
        />
        <Button
          type="button"
          onClick={submitHandler}
          disabled={!(qrCode && isDateValid)}
        >
          Start lending
        </Button>
      </Content>
      <Footer />
    </div>
  )
}

export default LendPage
