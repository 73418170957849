import React, { useState } from 'react'
import {
  Button,
  Content,
  Footer,
  Header,
  Input,
  Spinner,
} from '../../components'
import { auth, functions } from '../../firebase'
import globalStyles from '../../globalStyles'
import { getFormElementsArray, inputChangedHandler } from '../../utils'
import useStyles from './ContactPageStyles'

const ContactPage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = useStyles()

  const [contactForm, setContactForm] = useState({
    question: {
      elementType: 'multiline',
      elementConfig: {
        type: 'text',
        label: 'Question*',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
  })
  const [loading, setLoading] = useState(false)

  const submitHandler = (event) => {
    event.preventDefault()
    setLoading(true)

    const formData = {
      emailAddress: auth.currentUser.email,
      name: auth.currentUser.displayName,
      question: contactForm.question.value,
    }

    const questionEmail = functions.httpsCallable('questionEmail')

    questionEmail(formData)
      .then((result) => {
        setLoading(false)
        history.goBack()
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title="Contact" history={history} />
      <Content contentContainerStyle={localClasses.contentContainer}>
        <h1>Ask a question</h1>
        <form onSubmit={submitHandler}>
          {getFormElementsArray(contactForm).map((formElement) => (
            <Input
              key={formElement.id}
              type={formElement.elementType}
              config={formElement.elementConfig}
              value={formElement.value}
              changed={(event) =>
                inputChangedHandler(
                  event,
                  formElement.id,
                  contactForm,
                  setContactForm
                )
              }
              invalid={!formElement.valid}
              touched={formElement.touched}
              shouldValidate={formElement.validation}
            />
          ))}
          <Button
            type="submit"
            disabled={!contactForm.question.value || loading}
          >
            Send
          </Button>
        </form>
        {loading && (
          <div className={localClasses.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </Content>
      <Footer />
    </div>
  )
}

export default ContactPage
