import React, { useState, useEffect } from 'react'
import globalStyles from '../../globalStyles'
import { makeStyles } from '@material-ui/core'
import { auth } from '../../firebase'
import { getLendingHistoryByUserId, useLastAssetElementRef } from '../../utils'
import { Card, Content, Footer, Header } from '../../components'
import { createAssetFromLendingObject } from '../../utils/AssetUtils'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: '600px',
    '@media(max-width: 620px)': {
      width: '90%',
    },
  },
})

const LendingHistoryPage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = useStyles()

  const [lendingHistoryLoaded, setLendingHistoryLoaded] = useState()
  const [lendingHistory, setLendingHistory] = useState()

  const { lastAssetElementRef, listToUpdate, setListToUpdate, amountToAdd } =
    useLastAssetElementRef(lendingHistory)

  useEffect(() => {
    getLendingHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLendingHistory = async () => {
    const result = await getLendingHistoryByUserId(auth.currentUser.uid)
    if (result) {
      result.sort((a, b) => b.returnedAt - a.returnedAt)
      setLendingHistory(result)
      setListToUpdate(result.slice(0, amountToAdd))
    }
    setLendingHistoryLoaded(true)
  }

  const showLendingHistory = () => {
    if (lendingHistoryLoaded) {
      if (listToUpdate) {
        return (
          <div>
            {listToUpdate.map((lendingHistory, index) => {
              let ref = null
              if (index === listToUpdate.length - 1) {
                ref = lastAssetElementRef
              }
              return (
                <Card
                  key={lendingHistory.id}
                  ref={ref}
                  type="lendingHistory"
                  asset={createAssetFromLendingObject(lendingHistory)}
                />
              )
            })}
          </div>
        )
      }
      return <span>You have no history yet.</span>
    }
    return null
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title="Lending History" history={history} />
      <Content contentContainerStyle={localClasses.contentContainer}>
        {showLendingHistory()}
      </Content>
      <Footer />
    </div>
  )
}

export default LendingHistoryPage
