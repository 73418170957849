import React from 'react'
import useStyles from './ContentStyles'

const Content = ({ children, contentContainerStyle = null }) => {
  const localClasses = useStyles()

  let contentStyle = localClasses.contentContainer
  if (contentContainerStyle) {
    contentStyle = contentContainerStyle
  }

  return (
    <div className={localClasses.root}>
      <div className={localClasses.sideContainer}></div>
      <div className={contentStyle}>{children}</div>
      <div className={localClasses.sideContainer}></div>
    </div>
  )
}

export default Content
