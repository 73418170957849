import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: '600px',
    '@media(max-width: 620px)': {
      width: '90%',
    },
  },
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  qrCode: {
    marginTop: '10px',
  },
  marginTop: {
    marginTop: '5px',
  },
  qrReaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  qrReaderContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    overflow: 'hidden',
    '@media(max-width: 620px)': {
      width: '100%',
    },
  },
  spinnerContainer: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
})

export default useStyles
