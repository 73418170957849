import React, { useState, useEffect } from 'react'
import QrCode from 'qrcode.react'
import {
  Input,
  Button,
  QrReader,
  Header,
  Content,
  Footer,
  Spinner,
} from '../../components'
import {
  inputChangedHandler,
  getFormElementsArray,
  getAdminUid,
  initializeCities,
  addAsset,
  createLibraryRef,
  capitalize,
} from '../../utils'
import { auth } from '../../firebase'
import globalStyles from '../../globalStyles'
import localStyles from './AddPageStyles'
import { useGeolocation, useSentenceEncoder } from '../../contexts'

import UniqueAssetFields, {
  downloadQr,
  getValueToEmbed,
  createUniqueQrCode,
} from './environmentProvider'
import { getAsset } from '../../asset'

const AddPage = ({ history }) => {
  const localClasses = localStyles()
  const globalClasses = globalStyles()

  const { libraries } = useGeolocation()
  const { model, loadModel } = useSentenceEncoder()

  // These fields are always used regardless of whether it is a book or hardware
  const [defaultAssetFields, setDefaultAssetFields] = useState({
    owner: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: 'ML6', displayValue: 'ML6' },
          { value: 'Me', displayValue: 'Me' },
        ],
        label: 'Owner',
      },
      value: 'ML6',
      shouldValidate: false,
    },
    libraryLocation: {
      elementType: 'select',
      elementConfig: {
        options: [],
        label: 'Office location',
      },
      value: '',
      shouldValidate: false,
    },
  })
  const [qrCode, setQrCode] = useState(null)
  const [qrCodeDownloaded, setQrCodeDownloaded] = useState(null)
  const [qrCodeValidationTest, setQrCodeValidationTest] = useState(null)
  // to get the values of the specific asset (hardware or book) fields
  const [partialAsset, setPartialAsset] = useState(null)
  // to keep track of the overal validity of the specific asset fields
  const [partialAssetValid, setPartialAssetValid] = useState(false)

  const [embedding, setEmbedding] = useState(null)
  const [loadingEmbedding, setLoadingEmbedding] = useState(false)
  const [addingAssetClicked, setAddingAssetClicked] = useState(false)

  useEffect(() => {
    loadModel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // to embed the the text when there is a qr code generated
  useEffect(() => {
    if (qrCode) {
      if (model) {
        // loading because embedding the text takes some time
        setLoadingEmbedding(true)
        model.embed([getValueToEmbed(partialAsset)]).then((embedding) => {
          // convert embedding to usable array
          embedding.array().then((result) => {
            setLoadingEmbedding(false)
            setEmbedding(result[0])
          })
        })
      } else {
        // loading because model isn't fully loaded yet.
        setLoadingEmbedding(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, qrCode])

  // to save the asset, only when the title is embedded and the 'Add Asset'-button is clicked
  useEffect(() => {
    if (addingAssetClicked && embedding) {
      saveAsset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingAssetClicked, embedding])

  useEffect(() => {
    if (libraries) {
      const options = libraries.map((library) => {
        return {
          value: library.id,
          displayValue: library.city,
        }
      })
      initializeCities(
        options,
        'libraryLocation',
        defaultAssetFields,
        setDefaultAssetFields
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraries])

  const saveAsset = async () => {
    const formData = {}
    for (let key in defaultAssetFields) {
      formData[key] = defaultAssetFields[key].value
    }

    let ownerId = auth.currentUser.uid
    let ownerName = auth.currentUser.displayName
    if (formData['owner'] === 'ML6') {
      ownerId = await getAdminUid()
      ownerName = 'ML6'
    }

    const defaultAssetInfo = {
      ownerId,
      ownerName,
      libraryRef: createLibraryRef(formData['libraryLocation']),
      qrCode,
      lender: null,
      lendingRef: null,
      embedding,
      assetType: getAsset().value,
    }

    const asset = {
      ...partialAsset,
      ...defaultAssetInfo,
    }
    await addAsset(asset, history)
  }

  const submitHandler = async (event) => {
    event.preventDefault()
    setAddingAssetClicked(true)
  }

  const getScanText = () => {
    if (!qrCodeValidationTest) {
      return 'Scan qr code'
    }
    if (qrCode === qrCodeValidationTest) {
      return 'Validation succesful!'
    }
    return "Codes don't match"
  }

  const qrCodesMatch = () => {
    if (qrCode !== null && qrCodeDownloaded !== false) {
      return qrCode === qrCodeValidationTest
    }
    return false
  }

  const showForm = () => {
    let form = (
      <form onSubmit={submitHandler}>
        <UniqueAssetFields
          setPartialAsset={setPartialAsset}
          setQrCodeDownloaded={setQrCodeDownloaded}
          setPartialAssetValid={setPartialAssetValid}
        />
        {getFormElementsArray(defaultAssetFields).map((formElement) => {
          return (
            <Input
              key={formElement.id}
              type={formElement.elementType}
              config={formElement.elementConfig}
              value={formElement.value}
              changed={(event) =>
                inputChangedHandler(
                  event,
                  formElement.id,
                  defaultAssetFields,
                  setDefaultAssetFields
                )
              }
              shouldValidate={formElement.shouldValidate}
            />
          )
        })}
        <div>
          <Button
            type="Button"
            onClick={() => setQrCodeDownloaded(false)}
            disabled={!partialAssetValid}
          >
            Generate QR code
          </Button>
        </div>
        {showQrCode()}
        {showQrCodeReader()}
        <div>
          <Button disabled={!qrCodesMatch()} type="submit">
            Add {getAsset().value}
          </Button>
        </div>
        {addingAssetClicked && loadingEmbedding && (
          <div className={localClasses.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </form>
    )
    return form
  }

  const showQrCode = () => {
    if (qrCodeDownloaded === false) {
      // check if owner changed and update qrCode
      const uniqueQrCode = createUniqueQrCode(
        defaultAssetFields.owner.value,
        partialAsset
      )
      const newOwner = uniqueQrCode.split('@timestamp@')[0]
      const oldOwner = qrCode?.split('@timestamp@')[0]
      if (newOwner !== oldOwner) {
        setQrCode(uniqueQrCode)
      }

      return (
        <div className={localClasses.qrCodeContainer}>
          <QrCode
            id="qrCodeId"
            value={qrCode}
            renderAs={'canvas'}
            size={160}
            className={localClasses.qrCode}
          />
          <Button
            type="button"
            onClick={() => downloadQr(partialAsset, setQrCodeDownloaded)}
          >
            Download Qr
          </Button>
        </div>
      )
    }
    return null
  }

  const showQrCodeReader = () => {
    if (qrCodeDownloaded) {
      return (
        <div
          className={[
            localClasses.qrReaderWrapper,
            localClasses.marginTop,
          ].join(' ')}
        >
          <QrReader
            updateStateMethod={setQrCodeValidationTest}
            containerStyles={localClasses.qrReaderContainer}
            scanText={getScanText()}
            valid={qrCodesMatch()}
          />
        </div>
      )
    }
    return null
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header title={`Add ${capitalize(getAsset().value)}`} history={history} />
      <Content contentContainerStyle={localClasses.contentContainer}>
        {showForm()}
      </Content>
      <Footer />
    </div>
  )
}

export default AddPage
