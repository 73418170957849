const transformSecondsToDateString = (seconds) => {
  try {
    if (typeof seconds !== 'number') {
      throw new Error('Wrong type')
    }
    return new Date(seconds * 1000).toISOString().slice(0, 10)
  } catch (error) {
    return null
  }
}

const getStringFromDate = (date) => {
  try {
    return date.toISOString().slice(0, 10)
  } catch (error) {
    return null
  }
}

export { transformSecondsToDateString, getStringFromDate }
