import { isBook, isHardware } from '../asset'

const createAssetFromLendingObject = (lending) => {
  const defaultProperties = {
    startDateInSeconds: lending.startDate.seconds,
    returnDateInSeconds: lending.returnDate.seconds,
    isAssetReturned: lending.isAssetReturned,
    returnedAtInSeconds: lending.returnedAt ? lending.returnedAt.seconds : null,
    lendingId: lending.id,
    assetId: lending.assetRef.id,
    assetOwnerName: lending.assetOwnerName,
  }

  if (isBook()) {
    return {
      ...defaultProperties,
      title: lending.bookTitle,
      authors: lending.authors,
      imgLink: lending.bookImgLink,
    }
  }
  if (isHardware()) {
    return {
      ...defaultProperties,
      name: lending.assetName,
    }
  }
}

export { createAssetFromLendingObject }
