import React from 'react'
import QrReader from 'react-qr-reader'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  scanText: {
    backgroundColor: 'rgb(255, 50, 50)',
    boxSizing: 'border-box',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    fontSize: 'x-large',
  },
  scanTextValid: {
    backgroundColor: 'green',
    boxSizing: 'border-box',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    fontSize: 'x-large',
  },
})

const QrReaderCustom = ({
  valid,
  scanText,
  updateStateMethod,
  containerStyles = null,
  initializeErrors = null,
}) => {
  const classes = useStyles()
  let rootStyle = classes.root
  if (containerStyles) {
    rootStyle = containerStyles
  }

  let textStyle = classes.scanText
  if (valid) {
    textStyle = classes.scanTextValid
  }

  const handleScan = (data) => {
    if (data) {
      updateStateMethod(data)
      if (initializeErrors) {
        initializeErrors()
      }
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  return (
    <div className={rootStyle}>
      <span className={textStyle}>{scanText}</span>
      <QrReader
        delay={1000}
        onScan={handleScan}
        onError={handleError}
        showViewFinder={false}
      />
    </div>
  )
}

export default QrReaderCustom
