import { isBook } from '../asset'

const getBackgroundColor = () => {
  if (isBook()) {
    return 'black'
  } else {
    return 'grey'
  }
}

export { getBackgroundColor }
