import { makeStyles } from '@material-ui/core'
import { getBackgroundColor } from '../../utils'

const useStyles = makeStyles({
  root: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getBackgroundColor(),
  },
  footerText: {
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      color: 'rgb(255,145,58, 0.85)',
    },
  },
})

export default useStyles
