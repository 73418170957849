import { useState, useRef, useCallback } from 'react'

const useLastAssetElementRef = (fullList) => {
  const amountToAdd = 5
  const [listToUpdate, setListToUpdate] = useState(null)
  const observer = useRef()

  const lastAssetElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      // to check if last element from listToUpdate is visible
      // if visible => add more items to list
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          listToUpdate.length < fullList.length
        ) {
          // to check for special end case
          if (listToUpdate.length + amountToAdd >= fullList.length) {
            setListToUpdate(fullList)
          } else {
            setListToUpdate(
              fullList.slice(0, listToUpdate.length + amountToAdd)
            )
          }
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listToUpdate]
  )

  return {
    lastAssetElementRef,
    listToUpdate,
    setListToUpdate,
    amountToAdd,
  }
}

export { useLastAssetElementRef }
