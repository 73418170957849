import React from 'react'
import {
  TextField,
  Select,
  InputLabel,
  makeStyles,
  MenuItem,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '10px',
  },
  inputProps: {
    fontSize: 'large',
  },
})

const Input = ({
  invalid,
  shouldValidate,
  touched,
  type,
  config,
  value,
  changed,
}) => {
  let inputElement = null
  let error = false
  const classes = useStyles()

  // to handle invalid input
  if (invalid && shouldValidate && touched) {
    error = true
  }

  switch (type) {
    case 'input':
      inputElement = (
        <TextField
          error={error}
          className={classes.root}
          {...config}
          value={value}
          onChange={changed}
          inputProps={{ style: { fontSize: 'large' } }}
        />
      )
      break
    case 'select':
      inputElement = (
        <>
          <InputLabel>{config.label}</InputLabel>
          <Select value={value} onChange={changed} className={classes.root}>
            {config.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.displayValue}
              </MenuItem>
            ))}
          </Select>
        </>
      )
      break
    case 'multiline':
      inputElement = (
        <TextField
          multiline
          rows={10}
          error={error}
          className={classes.root}
          {...config}
          value={value}
          onChange={changed}
          inputProps={{ style: { fontSize: 'large' } }}
        />
      )
      break
    default:
      inputElement = null
  }

  return <div>{inputElement}</div>
}

export default Input
