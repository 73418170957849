import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { auth, provider } from './firebase'
import {
  HomePage,
  LendPage,
  AddPage,
  OwnAssetsPage,
  CatalogPage,
  DetailPage,
  LendingHistoryPage,
  BookSuggestionPage,
  ContactPage,
} from './containers'
import { makeStyles } from '@material-ui/core'
import { capitalize, ScrollToTop } from './utils'
import { GeolocationProvider, SentenceEncoderProvider } from './contexts'
import { Spinner } from './components'
import { getAsset, isBook } from './asset'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const App = () => {
  const localClasses = useStyles()
  const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    document.title = `ML6 | ${capitalize(getAsset().value)} Library`

    auth.onAuthStateChanged(() => {
      if (!auth.currentUser) {
        auth.getRedirectResult().then((result) => {
          if (result.user) {
            auth.updateCurrentUser(result.user)
          } else {
            auth.signInWithRedirect(provider)
          }
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
    })
  }, [currentUser])

  return (
    <BrowserRouter>
      <ScrollToTop />
      {currentUser ? (
        <GeolocationProvider>
          <SentenceEncoderProvider>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/lend" exact component={LendPage} />
              <Route path="/add" exact component={AddPage} />
              <Route
                path={`/my${getAsset().plural}`}
                exact
                component={OwnAssetsPage}
              />
              <Route path="/catalog" exact component={CatalogPage} />
              <Route
                path={`/catalog/:identifier`}
                exact
                component={DetailPage}
              />
              <Route path="/history" exact component={LendingHistoryPage} />
              {isBook() && (
                <Route
                  path="/suggestion"
                  exact
                  component={BookSuggestionPage}
                />
              )}
              <Route path="/contact" exact component={ContactPage} />
              <Redirect to="/" />
            </Switch>
          </SentenceEncoderProvider>
        </GeolocationProvider>
      ) : (
        <div className={localClasses.root}>
          <Spinner />
        </div>
      )}
    </BrowserRouter>
  )
}

export default App
