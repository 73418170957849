import { makeStyles } from '@material-ui/core'

const globalStyles = makeStyles({
  pageRoot: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    backgroundColor: 'black',
    borderRadius: '5px',
    color: 'white',
    marginBottom: '10px',
    padding: '10px',
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 'xx-large',
    fontWeight: 'normal',
    textAlign: 'center',
  },
})

export default globalStyles
