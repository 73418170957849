import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import globalStyles from '../../globalStyles'
import { makeStyles } from '@material-ui/core'
import {
  capitalize,
  getAllAssetsByOwnerId,
  useLastAssetElementRef,
} from '../../utils'
import { Button, Card, Content, Footer, Header } from '../../components'
import { getAsset, isBook } from '../../asset'

const useStyles = makeStyles({
  contentContainer: {
    margin: '10px 0',
    width: '600px',
    '@media(max-width: 620px)': {
      width: '90%',
    },
  },
  subtitleContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subtitle: {
    justifySelf: 'flex-start',
    flex: 1,
  },
  assetAmount: {
    justifySelf: 'flex-end',
  },
})

const OwnAssetsPage = ({ history }) => {
  const globalClasses = globalStyles()
  const localClasses = useStyles()

  const [assets, setAssets] = useState(null)
  const [assetIdToRemove, setAssetIdToRemove] = useState(null)

  const { lastAssetElementRef, listToUpdate, setListToUpdate, amountToAdd } =
    useLastAssetElementRef(assets)

  useEffect(() => {
    getOwnAssets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update assets after a asset is remove
  useEffect(() => {
    if (assetIdToRemove !== null) {
      const assetsCopy = [...assets]
      const idx = assetsCopy.findIndex((asset) => asset.id === assetIdToRemove)
      assetsCopy.splice(idx, 1)

      const listToUpdateCopy = [...listToUpdate]
      listToUpdateCopy.splice(idx, 1)

      if (assetsCopy.length > 0) {
        setAssets(assetsCopy)
        setListToUpdate(listToUpdateCopy)
      } else {
        setAssets(null)
        setListToUpdate(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetIdToRemove])

  const getOwnAssets = async () => {
    const result = await getAllAssetsByOwnerId(auth.currentUser.uid)
    if (result) {
      if (isBook()) {
        // sort list by title
        result.sort((a, b) => a.title.localeCompare(b.title))
      } else {
        result.sort((a, b) => a.name.localeCompare(b.name))
      }
      setAssets(result)
      setListToUpdate(result.slice(0, amountToAdd))
    }
  }

  const addAssetHandler = () => {
    history.push('add')
  }

  const showAssets = () => {
    if (listToUpdate) {
      return (
        <div>
          {listToUpdate.map((asset, index) => {
            let ref = null
            if (index === listToUpdate.length - 1) {
              ref = lastAssetElementRef
            }
            return (
              <Card
                key={asset.id}
                ref={ref}
                type="ownBook"
                asset={{ ...asset, assetId: asset.id }}
                setAssetIdToRemove={setAssetIdToRemove}
              />
            )
          })}
        </div>
      )
    }
    return <span>You haven't added a {getAsset().value} yet.</span>
  }

  return (
    <div className={globalClasses.pageRoot}>
      <Header
        title={`${capitalize(getAsset().plural)} I own`}
        history={history}
      />
      <Content contentContainerStyle={localClasses.contentContainer}>
        <Button type="button" onClick={addAssetHandler}>
          Add {getAsset().value}
        </Button>
        <div className={localClasses.subtitleContainer}>
          <h2 className={localClasses.subtitle}>
            My {capitalize(getAsset().plural)}
          </h2>
          {assets && (
            <span className={localClasses.assetAmount}>
              {capitalize(getAsset().plural)} found: {assets.length}
            </span>
          )}
        </div>
        {showAssets()}
      </Content>
      <Footer />
    </div>
  )
}

export default OwnAssetsPage
