import React, { useState, createContext, useContext, useEffect } from 'react'
import { getAllLibraries } from '../utils'

const GeolocationContext = createContext()

const useGeolocation = () => {
  return useContext(GeolocationContext)
}

const GeolocationProvider = ({ children }) => {
  //   const [permissionDenied, setPermissionDenied] = useState(false)
  const [libraries, setLibraries] = useState(null)

  useEffect(() => {
    setLibrariesWithoutCurrentPosition()
    if ('geolocation' in navigator) {
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const result = await getAllLibraries()

          // calculate the distance foreach city
          const librariesWithDistance = await getLibrariesWithDistance(
            position,
            result
          )

          // sort libraries from lowest distance to highest distance
          librariesWithDistance.sort((a, b) =>
            a.distance > b.distance ? 1 : -1
          )
          setLibraries(librariesWithDistance)
        },
        (error) => {
          // Error could be thrown if permission denied
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setLibrariesWithoutCurrentPosition = async () => {
    setLibraries(await getAllLibraries())
  }

  const getLibrariesWithDistance = async (position, libraries) => {
    return libraries.map((library) => {
      // current position
      const point1 = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }

      // fictieve huidige locatie: Berlin
      // const point1 = {
      //   latitude: 52.67321,
      //   longitude: 13.747812,
      // }

      // fictieve huidige locatie: Zurich
      // const point1 = {
      //   latitude: 47.3735086,
      //   longitude: 8.5407631,
      // }

      // position of library
      const point2 = {
        latitude: library.coordinates.latitude,
        longitude: library.coordinates.longitude,
      }

      const distance = calculateDistance(point1, point2)
      return { ...library, distance: distance }
    })
  }

  // source: https://www.movable-type.co.uk/scripts/latlong.html
  // haversine formula
  const calculateDistance = (point1, point2) => {
    const { latitude: lat1, longitude: lon1 } = point1
    const { latitude: lat2, longitude: lon2 } = point2
    const R = 6371e3 // metres
    const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180
    const Δφ = ((lat2 - lat1) * Math.PI) / 180
    const Δλ = ((lon2 - lon1) * Math.PI) / 180

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const d = R * c // in metres
    return d
  }

  const value = {
    libraries,
  }

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  )
}

export { GeolocationProvider, useGeolocation }
