import React from 'react'
import { Button as ButtonMUI, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    padding: '5px',
    fontSize: 'large',
    width: '100%',
    marginTop: '10px',
    backgroundColor: 'rgb(255,145,58, 0.85)',
    '&:hover': {
      backgroundColor: 'rgba(255, 142, 56, 0.7)',
    },
  },
})

const Button = ({ type, onClick, disabled, children, parentStyle = null }) => {
  const classes = useStyles()
  return (
    <ButtonMUI
      variant="contained"
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.root, parentStyle)}
    >
      {children}
    </ButtonMUI>
  )
}

export default Button
