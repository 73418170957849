import React, { useState } from 'react'
import { axiosGoogleBooks } from '../../utils'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Input } from '../../components'

const BookFields = ({
  setPartialAsset,
  setQrCodeDownloaded,
  setPartialAssetValid,
}) => {
  // this variable is used for resetting the title from outside the autocomplete component
  const [tempTitle, setTempTitle] = useState({})
  const [tempTitleOptions, setTempTitleOptions] = useState([])
  const [bookFormAuthor, setBookFormAuthor] = useState({
    elementType: 'input',
    elementConfig: {
      type: 'text',
      label: 'Author',
    },
    value: '',
    shouldValidate: false,
  })

  const handleAuthorInput = (event) => {
    event.preventDefault()
    const tempAuthor = { ...bookFormAuthor }
    tempAuthor.value = event.target.value
    // reset title and selected book
    setTempTitle({})
    setTempTitleOptions([])
    setPartialAsset(null)
    setPartialAssetValid(false)
    setBookFormAuthor(tempAuthor)
  }

  const handleTitleChange = (_, newValue) => {
    setTempTitle(newValue)
    setQrCodeDownloaded(null)
    if (newValue) {
      let imgLink = null
      if (
        newValue.volumeInfo.imageLinks &&
        newValue.volumeInfo.imageLinks.thumbnail
      ) {
        imgLink = newValue.volumeInfo.imageLinks.thumbnail.replace(
          'http://',
          'https://'
        )
      }
      setPartialAsset({
        title: newValue.volumeInfo.title,
        authors: newValue.volumeInfo.authors ?? null,
        publisher: newValue.volumeInfo.publisher ?? null,
        publishDate: newValue.volumeInfo.publishedDate ?? null,
        description: newValue.volumeInfo.description ?? null,
        category: newValue.volumeInfo.categories ?? null,
        language: newValue.volumeInfo.language ?? null,
        pageCount: newValue.volumeInfo.pageCount ?? null,
        imgLink: imgLink,
      })
      setPartialAssetValid(true)
    } else {
      setPartialAsset(null)
      setPartialAssetValid(false)
    }
  }

  const handleTitleInputChange = (_, value, reason) => {
    if (reason === 'input') {
      let query = `volumes?q=intitle:"${value.trim()}"`
      if (bookFormAuthor.value) {
        query = query + `+inauthor:"${bookFormAuthor.value}"`
      }

      axiosGoogleBooks.get(query).then((response) => {
        if (response.data.items) {
          setTempTitleOptions(response.data.items)
        } else {
          setTempTitleOptions([])
        }
      })
    }
    if (reason === 'clear') {
      setTempTitleOptions([])
    }
  }

  const showAuthors = (authorsList) => {
    if (authorsList) {
      return authorsList.join(', ')
    }
    return ''
  }

  return (
    <>
      <Autocomplete
        value={tempTitle}
        autoSelect={false}
        style={{ marginBottom: '10px' }}
        onChange={handleTitleChange}
        onInputChange={handleTitleInputChange}
        options={tempTitleOptions}
        getOptionLabel={(option) => {
          if (option && option.volumeInfo) {
            return `${option.volumeInfo.title} (${showAuthors(
              option.volumeInfo.authors
            )})`
          }
          return ''
        }}
        renderInput={(params) => <TextField {...params} label="Title" />}
      />
      <Input
        type={bookFormAuthor.elementType}
        config={bookFormAuthor.elementConfig}
        value={bookFormAuthor.value}
        changed={handleAuthorInput}
        shouldValidate={bookFormAuthor.shouldValidate}
      />
    </>
  )
}

export default BookFields
