const assetConfig = {
  book: {
    value: 'book',
    plural: 'books',
    identifier: 'title',
  },
  hardware: {
    value: 'hardware',
    plural: 'hardware',
    identifier: 'name',
  },
}

const getAsset = () => {
  return assetConfig[process.env.REACT_APP_ASSET]
}

const isBook = () => {
  return process.env.REACT_APP_ASSET === assetConfig.book.value
}

const isHardware = () => {
  return process.env.REACT_APP_ASSET === assetConfig.hardware.value
}

export { assetConfig, getAsset, isBook, isHardware }
